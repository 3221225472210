import { useState } from "react";
import Greeting from "../../components/greeting/Greeting";
import Button from "../../components/button/Button";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const HomePage: React.FC = () => {
  const [showIntro, setShowIntro] = useState(true);
  const navigate = useNavigate();

  const handleNavigation = (urlPath: string) => {
    navigate(urlPath);
  };

  const toggleIntro = () => {
    setShowIntro(!showIntro);
  };
  return (
    <div className="HomePage">
      {showIntro && (
        <>
          <Greeting />
          <Button onClick={toggleIntro} text="Next" icon={faArrowRight} />
        </>
      )}
      {!showIntro && (
        <>
          <Button onClick={toggleIntro} text="Back" icon={faArrowLeft} />
          <p>What are you looking for?</p>
          <Button text={`About Me`} onClick={() => handleNavigation("/about-me")} />
          <Button text={`Portfolio (WIP)`} onClick={() => console.log("do something")} />
          <Button text={`Other (WIP)`} onClick={() => console.log("do something")} />
          <Button text={`Game`} onClick={() => handleNavigation("/game")} />
        </>
      )}
    </div>
  );
};

export default HomePage;
