import { useNavigate } from "react-router-dom";
import Button from "../../components/button/Button";
import "./AboutMePage.css";

const AboutMePage: React.FC = () => {
  const navigate = useNavigate();
  const handleNavigation = (urlPath: string) => {
    navigate(urlPath);
  };
  return (
    <div className="aboutMeContainer">
      <Button text="Home" onClick={() => handleNavigation("/")} />
      <h2 className="aboutMeHeading">About me!</h2>
      <h3 className="aboutMeSubheading">Hey there, I'm Don Walsh, a software developer from Calgary.</h3>

      <div className="aboutMeText">
        Language has always been a natural strength for me. It goes beyond just words – it's the way it connects people and bridges ideas that truly
        fascinates me. So, it makes perfect sense that this love for communication eventually led me to the world of programming. It wasn't a sudden
        revelation, but a gradual realization that code could be another powerful language for me to wield. It all started young, just messing around
        and building bots and games for fun. The inner workings of computer systems were incredibly captivating – the intricate dance between
        versatility and specificity felt like an endless playground of discovery.
      </div>
      <div className="aboutMeText">
        As I honed my skills through experimentation and learning, I discovered that this wasn't just a hobby – I could actually turn this passion
        into a fulfilling career. While I didn't pursue a traditional educational path, my ability to learn independently has become one of my
        greatest strengths, and an asset in shaping who I am today. This self-reliance has served me well in other areas of my life too, like
        developing fluency in Korean as a second language, or picking up new instruments. My fitness journey, focused on bodybuilding, is another
        example of my dedication to continuous learning and self-improvement.
      </div>
      <div className="aboutMeText">
        Before I decided to take the leap into software development, I was a leader in customer service at a local bubble tea shop. What I loved most
        about that job was the agency I had to create unique and positive experiences for each customer. Going the extra mile and exceeding their
        expectations brought me a lot of joy. Beyond customer service, I've also explored a variety of hands-on activities, including gardening,
        landscaping, and carpentry – I like to keep things interesting and have a pretty diverse background!
      </div>
      <h3>Looking Ahead:</h3>
      <div className="aboutMeText">
        The journey of self-discovery is a never-ending path, and that excites me. I'm a lifelong learner, driven by a desire to keep expanding my
        knowledge and making a positive impact through the things I create. I'm always looking for new challenges and opportunities to grow, both as a
        programmer and as a person.
      </div>
    </div>
  );
};

export default AboutMePage;
