import Entity from "../entities/entity";
import Monster from "../entities/monster";
import Player from "../entities/player";
import Projectile from "../entities/projectile";

export class Renderer {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d")!;
  }

  render(entities: Entity[]) {
    if (!this.ctx) return;

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.fillStyle = "black";
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height); // Background fill

    for (const entity of entities) {
      if (entity instanceof Player || entity instanceof Monster) {
        this.ctx.beginPath();
        this.ctx.arc(
          entity.x + entity.width / 2,
          entity.y + entity.height / 2,
          entity.width / 2,
          0,
          2 * Math.PI
        );
        this.ctx.fillStyle = entity.color;
        this.ctx.fill();
      }
      if (entity instanceof Projectile) {
        this.ctx.fillStyle = entity.color;
        this.ctx.fillRect(entity.x, entity.y, entity.width, entity.height);
      }
    }
  }
}
