import Entity from "../entities/entity";

export class EntityManager {
  entities: Entity[] = [];
  projectilesToRemove: Entity[] = [];

  addEntity(entity: Entity) {
    this.entities.push(entity);
  }

  isOutOfBounds(entity: Entity, canvas: HTMLCanvasElement): boolean {
    return (
      entity.x < 0 ||
      entity.y < 0 ||
      entity.x + entity.width > canvas.width ||
      entity.y + entity.height > canvas.height
    );
  }

  checkCollision(entity1: Entity, entity2: Entity): boolean {
    return (
      entity1.x < entity2.x + entity2.width &&
      entity1.x + entity1.width > entity2.x &&
      entity1.y < entity2.y + entity2.height &&
      entity1.y + entity1.height > entity2.y
    );
  }
}
