import Player from "./player";
import Monster from "./monster";
import Entity from "./entity";
import Engine from "../core/Engine";

export interface EntityCreationOptions {
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
  speed: number;
}

function createEntity(
  entityType: string,
  options: EntityCreationOptions,
  engine: Engine
): Entity {
  switch (entityType) {
    case "player":
      return new Player(
        options.x,
        options.y,
        options.width,
        options.height,
        options.color,
        options.speed
      );
    case "monster":
      return new Monster(
        options.x,
        options.y,
        options.width,
        options.height,
        options.color,
        options.speed,
        engine
      );
    default:
      throw new Error(`Unsupported entity type: ${entityType}`);
  }
}

// Convenience functions for specific entity types
function createPlayer(
  canvasWidth: number,
  canvasHeight: number,
  engine: Engine
): Player {
  return createEntity(
    "player",
    {
      x: canvasWidth / 2,
      y: canvasHeight / 2,
      width: 30,
      height: 30,
      color: "green",
      speed: 2,
    },
    engine
  ) as Player;
}

function createMonsters(
  canvasWidth: number,
  canvasHeight: number,
  numMonsters: number,
  engine: Engine
): Monster[] {
  const monsters: Monster[] = [];
  const positions = [
    [0, 0],
    [canvasWidth - 20, 0],
    [0, canvasHeight - 20],
    [canvasWidth - 20, canvasHeight - 20],
  ];
  const colors = ["red", "blue", "orange", "purple"];

  for (let i = 0; i < numMonsters; i++) {
    monsters.push(
      createEntity(
        "monster",
        {
          x: positions[i][0],
          y: positions[i][1],
          width: 20,
          height: 20,
          color: colors[i],
          speed: 3,
        },
        engine
      ) as Monster
    );
  }
  return monsters;
}

export { createEntity, createPlayer, createMonsters };
