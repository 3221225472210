import Entity from "../entities/entity";
import Player from "../entities/player";
import { createMonsters, createPlayer } from "../entities/entityFactory";
import { EntityManager } from "../engineUtils/EntityManager";
import { InputHandler } from "../engineUtils/InputHandler";
import { Renderer } from "../engineUtils/Renderer";

export class Engine {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  entityManager: EntityManager;
  inputHandler: InputHandler;
  renderer: Renderer;
  lastRender: number = 0;
  player: Player | null = null;

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d")!;
    this.entityManager = new EntityManager();
    this.renderer = new Renderer(canvas);

    // Initialize inputHandler with null player initially
    this.inputHandler = new InputHandler(null);
  }

  update(deltaTime: number) {
    // Update all entities
    this.entityManager.entities.forEach((entity) => {
      if (entity instanceof Player) {
        entity.update(
          deltaTime,
          this.canvas,
          this.player as Player,
          this.inputHandler.keys
        );
      } else {
        entity.update(deltaTime, this.canvas, this.player as Player);
      }
    });

    // Check for out-of-bounds entities
    this.entityManager.entities = this.entityManager.entities.filter(
      (entity) => {
        if (this.entityManager.isOutOfBounds(entity, this.canvas)) {
          this.entityManager.projectilesToRemove.push(entity);
          return false; // Remove from entities
        }
        return true; // Keep in entities
      }
    );

    this.entityManager.entities = this.entityManager.entities.filter(
      (entity) => !this.entityManager.projectilesToRemove.includes(entity)
    );
    this.entityManager.projectilesToRemove = [];
  }

  render() {
    this.renderer.render(this.entityManager.entities);
  }

  gameLoop(timestamp: number = 0) {
    const deltaTime = (timestamp - this.lastRender) / 1000;
    this.lastRender = timestamp;
    this.update(deltaTime);
    this.render();

    requestAnimationFrame(this.gameLoop.bind(this));
  }

  start() {
    this.gameLoop();
    document.addEventListener("keydown", this.inputHandler.handleKeyDown);
    document.addEventListener("keyup", this.inputHandler.handleKeyUp);
  }

  stop() {
    document.removeEventListener("keydown", this.inputHandler.handleKeyDown);
    document.removeEventListener("keyup", this.inputHandler.handleKeyUp);
  }

  addEntity(entity: Entity) {
    this.entityManager.addEntity(entity);
    if (entity instanceof Player) {
      this.player = entity;
      this.inputHandler = new InputHandler(this.player);
    }
  }
}

export function initializeEngine(canvas: HTMLCanvasElement): Engine {
  const engine = new Engine(canvas);

  const { width, height } = canvas;
  const player = createPlayer(width, height, engine);
  const monsters = createMonsters(width, height, 4, engine);

  engine.addEntity(player);
  monsters.forEach((monster) => engine.addEntity(monster));
  engine.player = player; // Set the player explicitly

  return engine;
}

export default Engine;
