import { useRef } from "react";
import { useGameEngine } from "../hooks/useGameEngine";

interface GameProps {
  width?: number;
  height?: number;
}

function Game({ width = 800, height = 600 }: GameProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useGameEngine(canvasRef);

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      data-testid="game-canvas"
    />
  );
}

export default Game;
