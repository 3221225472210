import Engine from "../core/Engine";
import Entity from "./entity";
import Player from "./player";

class Projectile extends Entity {
  dx: number;
  dy: number;
  speed: number;
  public engine: Engine;

  constructor(
    x: number,
    y: number,
    dx: number,
    dy: number,
    speed: number,
    engine: Engine
  ) {
    super(x, y, 5, 5, "yellow", 1);
    this.dx = dx;
    this.dy = dy;
    this.speed = speed;
    this.engine = engine;
  }

  update(deltaTime: number, canvas?: HTMLCanvasElement, player?: Player) {
    if (!canvas || !this.engine) return;
    this.x += this.dx * this.speed;
    this.y += this.dy * this.speed;

    if (player && this.engine.entityManager.checkCollision(this, player)) {
      player.handleCollision();
      this.engine.entityManager.projectilesToRemove.push(this);
      return;
    }
  }
}

export default Projectile;
