import Entity from "./entity";

class Player extends Entity {
  update(
    deltaTime: number,
    canvas: HTMLCanvasElement,
    _?: Player,
    keys?: { [key: string]: boolean }
  ) {
    if (!keys || !canvas) return;

    const velocityX = (keys["d"] ? 1 : 0) - (keys["a"] ? 1 : 0);
    const velocityY = (keys["s"] ? 1 : 0) - (keys["w"] ? 1 : 0);

    // Update position based on deltaTime and velocity
    this.x += velocityX * this.speed;
    this.y += velocityY * this.speed;

    // Keep the player within canvas bounds
    this.x = Math.max(0, Math.min(this.x, canvas.width - this.width));
    this.y = Math.max(0, Math.min(this.y, canvas.height - this.height));
  }

  handleCollision() {
    // Handle collision with player
    console.log("ouch!");
  }
}

export default Player;
