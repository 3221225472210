import { useRef, useEffect } from "react";
import { initializeEngine, Engine } from "../engine/core/Engine";

export function useGameEngine(canvasRef: React.RefObject<HTMLCanvasElement>) {
  const engineRef = useRef<Engine | null>(null);
  const initializedRef = useRef(false);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas && !initializedRef.current) {
      try {
        engineRef.current = initializeEngine(canvas);
        engineRef.current.start();
        initializedRef.current = true;
      } catch (error) {
        console.error("Failed to initialize the engine:", error);
      }
    }

    return () => {
      if (engineRef.current) {
        engineRef.current.stop();
        engineRef.current = null;
        initializedRef.current = false;
      }
    };
  }, [canvasRef]); // Ensure the effect runs only once when the component mounts

  return engineRef;
}
