import "./App.css";
import "../themes.css";
import HomePage from "./pages/home-page/HomePage";
import AboutMePage from "./pages/about-me-page/AboutMePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import GamePage from "./pages/game-page/GamePage";

const App: React.FC = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-me" element={<AboutMePage />} />
          <Route path="/game" element={<GamePage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
